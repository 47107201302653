import "./Main.css";
import React from "react";

function Main({
  children,
}) {

  return (
    <>
      <main className={'main'}>{children}</main>
    </>
  )
}

export default Main;
