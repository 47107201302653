import './CheckboxSearch.css';
import React from "react";

function CheckboxSearch() {

  return (
    <>
      <span className='checkbox__new' />
    </>
  )
}

export default CheckboxSearch;
