export const configHeaderNotLogin = {
  links: [
    {
      id: 1,
      to: '/sign-up',
      title: 'Регистрация',
    },
  ],
  linkEnter: {
    id: 2,
    to: '/sign-in',
    title: 'Войти',
  },
};

export default configHeaderNotLogin;