const configFormInputEmail = {
  idInput: 'inputEmail',
  typeInput: 'Email',
  nameInput: 'Email',
  textLabel: 'E-mail',
  required: true,
  placeholder: '',
};

export default configFormInputEmail;
